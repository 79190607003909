<template>
<div>
  <h3>Dashboard</h3>
</div>
</template>

<script>
export default {
  name: 'Dashboard',
  title: 'Dashboard',
};
</script>

<style scoped>

</style>
